import { AuthController } from 'context/auth/AuthContextController';
import { DictionaryController } from 'context/dictionary/DictionaryContextController';
import { LocaleController } from 'context/locale/LocaleContextController';
import { ModalController } from 'context/modal/ ModalContextController';
import { UIController } from 'context/ui/UIContextController';

import { AppProvidersProps } from './AppProviders.types';

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <LocaleController>
      <AuthController>
        <DictionaryController>
          <ModalController>
            <UIController>{children}</UIController>
          </ModalController>
        </DictionaryController>
      </AuthController>
    </LocaleController>
  );
};
