import React, { useState } from 'react';
import Image from 'next/image';
import clsx from 'clsx';

import { MobileMenu } from 'components/header/mobile-menu/mobile-menu';
import { ServicesDropdown } from 'components/services-dropdown/services-dropdown';
import TojjarIcon from 'assets/images/tojjar-icon.svg';
import BurgerIcon from 'assets/images/burger-icon.svg';
import { MENU_LINKS } from 'assets/data/menu-links';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { usePageType } from 'hooks/usePageType/usePageType';

import styles from './header.module.scss';

export const Header = () => {
  const { formatMessage } = useLocale();
  const [isMobileMenuOpen, setMobileMenu] = useState(false);
  const { isBooths, isApps } = usePageType();

  const handleMobileMenuClick = () => {
    setMobileMenu((prev) => !prev);
  };

  const isActiveClass = (linkName: string) => {
    return (linkName === 'Booths' && isBooths) || (linkName === 'Apps' && isApps);
  };

  if (isMobileMenuOpen) {
    return <MobileMenu onClose={handleMobileMenuClick} />;
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContent}>
          <div className={styles.headerHap}>
            <div className={styles.servicesButton}>
              <ServicesDropdown />
            </div>
            <div className={styles.divider} />
            <div className={styles.logo}>
              <Image priority src={TojjarIcon} alt="tojjar" />
            </div>
            <button className={styles.hamburgerButton} onClick={handleMobileMenuClick}>
              <Image priority src={BurgerIcon} height={12} width={18} alt="menu" />
            </button>
          </div>
          <div className={styles.links}>
            {MENU_LINKS.map((link) => (
              <a
                key={link.name}
                href={link.url}
                className={clsx(styles.link, isActiveClass(link.name) && styles.activeLink)}
              >
                {formatMessage({
                  id: AppMessages[link.translationKey],
                })}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
