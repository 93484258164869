import React from 'react';
import { useState, useLayoutEffect } from 'react';

export const usePortalPosition = (targetRef: React.RefObject<HTMLElement>) => {
  const [position, setPosition] = useState<{ top: number; left?: number; right?: number } | null>(null);

  useLayoutEffect(() => {
    if (targetRef?.current) {
      const { top, left, height, right } = targetRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (document.dir === 'rtl') {
        return setPosition({ top: top + height, right: viewportWidth - right });
      }

      setPosition({ top: top + height, left });
    }
  }, [targetRef]);

  return position;
};
