import * as React from 'react';
import { createPortal } from 'react-dom';

import { useOutsideClick } from 'hooks/useOutsideClick/useOutsideClick';
import { usePortalPosition } from 'hooks/usePortalPosition/usePortalPosition';
import { useRemoveScroll } from 'hooks/useRemoveScroll/useRemoveScroll';

import styles from './portal.module.scss';

type PortalProps = {
  onClose: () => void;
  anchorRef: React.RefObject<HTMLElement>;
  children: React.ReactNode;
};

export const Portal = ({ onClose, children, anchorRef }: PortalProps) => {
  useRemoveScroll();
  const ref = useOutsideClick(() => {
    onClose();
  });
  const position = usePortalPosition(anchorRef);

  return createPortal(
    <div className={styles.portal}>
      <div ref={ref} className={styles.content} style={{ ...position }}>
        {children}
      </div>
    </div>,
    document.body,
  );
};
