import { createContext, useState } from 'react';

import { ModalContextType, ModalControllerProps } from './ModalContext.types';
import { Modals } from './modals.enum';

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalController = ({ children }: ModalControllerProps) => {
  const [activeModals, setActiveModals] = useState<Modals[]>([]);

  const isOpen = (key: Modals) => activeModals.includes(key);

  const showModal = (key: Modals) => {
    setActiveModals((prev) => [...prev, key]);
  };

  const closeModal = (key: Modals) => {
    setActiveModals((prev) => prev.filter((element) => element !== key));
  };

  return <ModalContext.Provider value={{ isOpen, showModal, closeModal }}>{children}</ModalContext.Provider>;
};
