import { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useScrollToPageSection } from '../../hooks/useScrollToPageSection/useScrollToPageSection';

import { UIContextType, UIControllerProps } from './UIContext.types';

export const UIContext = createContext<UIContextType | undefined>(undefined);

export const UIController = ({ children }: UIControllerProps) => {
  const { query, isReady } = useRouter();

  const [activeTab, setActiveTab] = useState<number>(0);

  // NOTE: tab before confirmation
  const [targetTab, setTargetTab] = useState<number>(0);

  useEffect(() => {
    if (isReady) {
      // Name of the query param is `tab` and it can't be an array
      const tab = query.tab && !Array.isArray(query.tab) ? parseInt(query.tab) : 0;

      setActiveTab(tab);
    }
  }, [isReady]);

  return (
    <UIContext.Provider value={{ activeTab, setActiveTab, targetTab, setTargetTab }}>{children}</UIContext.Provider>
  );
};
