import { AppMessages } from 'i18n/messages';

export const MENU_LINKS: { name: string; translationKey: keyof typeof AppMessages; url: string }[] = [
  {
    name: 'Home',
    translationKey: 'footer.home',
    url: process.env.NEXT_PUBLIC_TOJJAR_URL,
  },
  {
    name: 'Apps',
    translationKey: 'footer.apps',
    url: process.env.NEXT_PUBLIC_TOJJAR_APPS_URL,
  },
  {
    name: 'Booths',
    translationKey: 'footer.booths',
    url: process.env.NEXT_PUBLIC_TOJJAR_BOOTHS_URL,
  },
];
