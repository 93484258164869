import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { ToastContainer, Zoom } from 'react-toastify';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import 'react-toastify/dist/ReactToastify.css';
import { useRouter } from 'next/router';

import { AppProviders } from 'providers/AppProviders';
import { Header } from 'components/header/header';
import 'styles/globals.scss';
import { translations } from 'i18n/messages';
import { AppLocale } from 'i18n/AppLocale.enum';
import Booth from 'assets/images/booth.svg';
import Apps from 'assets/images/apps.svg';
import { usePageType } from '../hooks/usePageType/usePageType';

const Seo = {
  initialize: () => {
    const codes = {
      twitter: 'ofie0',
      linkedin: '5738017',
    };

    const twitterSnippet = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','${codes.twitter}');`;

    const linkedinSnippets = [
      `_linkedin_partner_id = "${codes.linkedin}";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
      `(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);`,
    ];

    const scriptsToAttach = [twitterSnippet, ...linkedinSnippets].reverse();
    scriptsToAttach.forEach((snippet) => {
      const script = document.createElement('script');
      script.innerHTML = snippet;

      document.head.insertBefore(script, document.head.childNodes[0]);
    });
  },
};

const BOOTHS_GOOGLE_TAG = 'G-P05RV9H3H0';
const APPS_GOOGLE_TAG = 'G-CJ028D1MGM';

export default function App({ Component, pageProps }: AppProps) {
  const { locale } = useRouter();
  const title = translations[locale as AppLocale][`meta.title.${process.env.NEXT_PUBLIC_APP}`];
  const description = translations[locale as AppLocale][`meta.description.${process.env.NEXT_PUBLIC_APP}`];
  const { isApps } = usePageType();
  const googleTagId = isApps ? APPS_GOOGLE_TAG : BOOTHS_GOOGLE_TAG;

  useEffect(() => {
    Seo.initialize();
  }, []);

  return (
    <AppProviders>
      <Head>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${googleTagId}');`,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-55X3GXFQ');`,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-T67TD29G')`,
          }}
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title>{title}</title>
      </Head>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T67TD29G"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>

      <Header />
      <Component {...pageProps} />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        transition={Zoom}
      />
    </AppProviders>
  );
}
