import { createContext, useState, useEffect, useCallback } from 'react';

import { axiosClient } from '../../axios';

import { DictionaryContextType, DictionaryControllerProps } from './DictionaryContext.types';

export const DictionaryContext = createContext<DictionaryContextType | undefined>(undefined);

export const DictionaryController = ({ children }: DictionaryControllerProps) => {
  const [regions, setRegions] = useState<{ id: string; key: string; name_ar: string; name_en: string }[]>([]);
  const [cities, setCities] = useState<{ id: string; region_id: string; name_ar: string; name_en: string }[]>([]);
  const [isCitiesLoading, setCitiesLoading] = useState<boolean>(false);

  useEffect(() => {
    const getRegions = async () => {
      const { data } = await axiosClient.get(`/api/regions`);

      setRegions(data.regions);
    };

    getRegions();
  }, []);

  const getCities = useCallback(async (regionKey: string) => {
    setCitiesLoading(true);
    const { data } = await axiosClient.get(`/api/cities?region=${regionKey}`);

    setCities(data);
    setCitiesLoading(false);
  }, []);

  const clearCitiesList = useCallback(() => {
    setCities([]);
  }, []);

  return (
    <DictionaryContext.Provider value={{ regions, cities, getCities, isCitiesLoading, clearCitiesList }}>
      {children}
    </DictionaryContext.Provider>
  );
};
