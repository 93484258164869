import { AppLocale } from './AppLocale.enum';
import enMessages from './data/en.json';
import arMessages from './data/ar.json';

type KeyAsValue<T> = { [P in keyof T]: P };

const keysToValues = <T extends Record<string, string | object>>(source: T): KeyAsValue<typeof source> => {
  return (Object.keys(source) as Array<keyof T>).reduce((accumulated, current) => {
    accumulated[current] = current;
    return accumulated;
  }, {} as KeyAsValue<typeof source>);
};

export const AppMessages = {
  ...keysToValues(enMessages),
  ...keysToValues(arMessages),
};

export const translations: Record<AppLocale, Record<keyof typeof AppMessages, string>> = {
  [AppLocale.en]: enMessages,
  [AppLocale.ar]: arMessages,
};
