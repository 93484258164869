import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { AppLocale } from 'i18n/AppLocale.enum';

import { useLocaleTypes } from './useLocale.types';

export const useLocale = (): useLocaleTypes => {
  const intl = useIntl();
  const router = useRouter();

  const locale = useMemo(
    () => ({
      ...intl,
      changeLocale: (value: AppLocale) => {
        document.cookie = `NEXT_LOCALE=${value}`;

        router.push({ pathname: router.pathname, query: router.query }, router.asPath, { locale: value });
      },
    }),
    [intl, router],
  );

  return locale;
};
