import React from 'react';
import Image from 'next/image';

import MenuIcon from 'assets/images/menu-icon.svg';

import styles from './services-button.module.scss';

type ServicesButtonProps = {
  onClick: () => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

export const ServicesButton = ({ onClick, buttonRef }: ServicesButtonProps) => {
  return (
    <button ref={buttonRef} onClick={onClick} className={styles.servicesButton}>
      <Image priority src={MenuIcon} height={16} width={16} alt="menu" />
    </button>
  );
};
