import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';

import { AppLocale } from 'i18n/AppLocale.enum';
import { translations } from 'i18n/messages';

import { LocaleControllerProps } from './LocaleContextController.types';

export const LocaleController = ({ children }: LocaleControllerProps) => {
  const { locale, defaultLocale } = useRouter();

  useEffect(() => {
    const dir = locale === 'ar' ? 'rtl' : 'ltr';

    document.documentElement.dir = dir;
    document.documentElement.className = dir;
  }, [locale]);

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      locale={locale as AppLocale}
      messages={translations[locale as AppLocale]}
    >
      {children}
    </IntlProvider>
  );
};
