import { useEffect } from 'react';

export const useRemoveScroll = () => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);
};
